.pitchTracker {

  > h3 { margin-bottom: .5em; }

  .regulations {
    margin-top: 1em;

    ul { margin-top: 1em; }
    li { margin-left: 2.3em; }
  }
}
